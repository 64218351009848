<template>
  <v-container>
    <v-row>
      <v-col 
        class="mx-auto"
        xl="8"
        lg="9"
        md="12"
      >
      <v-row>
        <v-col
          class="mx-auto"
          lg="12"
        >
          <v-card>
            <v-card-title>Software Engineer Portfolio</v-card-title>
            <v-row>
              <v-col
                lg="6"
                cols="12"
              >
                <v-card-text class="body-1">
                  I'm a senior full stack software engineer with over 7 years of experience working across industry, charity and academic sectors. I have a diverse technical background in software engineering across the stack with a variety of technologies, often focused on using the right tool to solve the problem. I have a PhD in human-computer interaction and often lean towards product engineering. I'm a keen problem solver and love tackling business problems with simple, elegant tech solutions. 
                </v-card-text>
            </v-col>
            <v-col
              lg="6"
              cols="12"
            >
              <v-card-text class="body-1">
                <strong>Technical overview: </strong>I have a Computer Science background, predominantly fullstack experience with Typescript & Javascript. Modern web/mobile & front-end (Vue, React, Next) and back-end (Node, SQL, MongoDB, REST APIs, GraphQL). Mocha, Jest and Cypress testing. AWS: Lambda, EC2, DynamoDB, Cognito, API Gateway, Amplify, SQS. Python for data analytics & visualisation. Agile/scrum, Git, CI/CD pipelines, AWS & DO. Range of other technologies: chatbots, Alexa skills, hardware, Rust.
              </v-card-text>
            </v-col>
            </v-row>
            
            
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, index) in getPortfolioOverview"
          v-bind:key="index"
          xl="4"
          lg="6"
          sm="6"
        >
        <v-card>
          <v-row
            v-if="!item.thumb"
            justify="center"
          >
            <v-col class="mx-auto" cols="6">
              <v-icon
                class="pa-4"
                large
              >
                mdi-cloud-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-img
            v-if="item.thumb"
            :src="require(`@/assets/images/${item.thumb}`)"
            :alt="item.title"
            height="200px"
          >
          </v-img>
    
          <v-card-title>{{ item.title }}</v-card-title>
          <!-- <v-card-subtitle>
            {{ item.year}}
          </v-card-subtitle> -->
          <v-card-text>
            {{ item.prev }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              :to="`/portfolio/${item.name}`"
            >Read More</v-btn>
          </v-card-actions>
        </v-card>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'

// https://blog.lichter.io/posts/dynamic-images-vue-nuxt/
// Proposed way to solve images not resolving dynamically

export default {
  name: "Portfolio",

  data() {
    return {
      imgPlaceholder: "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
    }
  },

  computed: {
    ...mapGetters([
      'getPortfolioOverview'
    ]),
    thumbImage () {
      const fileName = 'sacred-harvest';
      return require(`../assets/images/${fileName}`)
    }
  }

}
</script>

<style>

</style>